// ** React Imports
import { useEffect, useState, createContext } from 'react'

// ** Create Context
const ThemeColors = createContext()

const ThemeContext = ({ children }) => {
  // ** State
  const [colors, setColors] = useState({})

  //** ComponentDidMount
  useEffect(() => {
    if (window !== 'undefined') {
      //** Get variable value
      const getHex = color => window.getComputedStyle(document.body).getPropertyValue(color).trim()

      //** Colors obj
      const obj = {
        primary: {
          light: getHex('--primary').concat('1a'),
          main: getHex('--primary')
        },
        secondary: {
          light: getHex('--secondary').concat('1a'),
          main: getHex('--secondary')
        },
        success: {
          light: getHex('--success').concat('1a'),
          main: getHex('--success')
        },
        danger: {
          light: getHex('--danger').concat('1a'),
          main: getHex('--danger')
        },
        warning: {
          light: getHex('--warning').concat('1a'),
          main: getHex('--warning')
        },
        info: {
          light: getHex('--info').concat('1a'),
          main: getHex('--info')
        },
        dark: {
          light: getHex('--dark').concat('1a'),
          main: getHex('--dark')
        },
        chart: {
          // https://atlassian.design/foundations/color-new/color-picker-swatches/
          gray: {boldest: '#44546f', bolder: '#758195', bold: '#8590a2', bgSubtler: '#dbdee3'},
          blue: {boldest: '#0055cc', bolder: '#1d7afc', bold: '#388bff', loading: '#c9dbff'},
          teal: {boldest: '#4d8b71', bolder: '#1d7f8c', bold: '#1d9aaa'},
          green: {boldest: '#216e4e', bolder: '#1f845a', bold: '#22a06b'},
          olive: {boldest: '#4c6b1f', bolder: '#5b7f24', bold: '#6a9a23'},
          yellow: {boldest: '#fdb933', bolder: '#b65c02', bold: '#d97008'},
          orange: {boldest: '#974f0c', bolder: '#b65c02', bold: '#d97008'},
          red: {boldest: '#ae2a19', bolder: '#e34935', bold: '#ef5c48', loading: '#f0c6c0'},
          magenta: {boldest: '#943d73', bolder: '#cd519d', bold: '#da62ac'},
          purple: {boldest: '#bf63f3', bolder: '#8270db', bold: '#8f7ee7'},
          text: {graySubtle: '#838ca1', grayDefault: '#091e42'}
        }
      }

      setColors({ ...obj })
    }
  }, [])

  return <ThemeColors.Provider value={{ colors }}>{children}</ThemeColors.Provider>
}

export { ThemeColors, ThemeContext }
