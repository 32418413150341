// ** Redux Imports
import { combineReducers } from "redux";

// ----------------------------------- Orignal code from template - safely remove commented section later - from here  --------------------------------------
// // ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
// import chat from '@src/views/apps/chat/store/reducer'
// import todo from '@src/views/apps/todo/store/reducer'
// import users from '@src/views/apps/user/store/reducer'
// import invoice from '@src/views/apps/invoice/store/reducer'
// import calendar from "@src/views/getting-started/calendar/store/reducer";
// import ecommerce from '@src/views/apps/ecommerce/store/reducer'
// import dataTables from '@src/views/tables/data-tables/store/reducer'
import personaDetails from "./sp/personaReducer";

const rootReducer = combineReducers({
  auth,
  //   todo,
  //   chat,
    // email,
  // users,
  navbar,
  layout,
  //   invoice,
  // calendar,
  //   ecommerce,
  //   dataTables
  personaDetails
});
// ----------------------------------- Orignal code from template - safely remove commented section later - to here  --------------------------------------

export default rootReducer;
