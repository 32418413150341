
// ** Initial State
const initialState = {
    ageRange: '', //'35-45',
    gender: '',   //'females',
    relationshipStatus: '',
    familySize: '',
    locationType: [], // ['tier3','tier1'],
    education: '',    //'graduation',
    incomeRange: '',  //'15-30L',
    profession: [],   //[{ id: 10, value: 'dentist', caption: 'Dentist', isFixed: true, label: 'Dentist' },],
    socialMedia: [],  // ['whatsapp'],
    otherMedia: [],   //['newspaper'],
    favBrands: '',    //'nykaa',
    challengesFaced: '', // 'challenge1, \nchallenge2',
    whatTheyBuy: '',  //'what1, what2',
    whereTheyBuy: '',
}

const personaReducer = (state = initialState, action) => {
  try {
    state = localStorage.getItem("personaDetails") ? JSON.parse(localStorage.getItem("personaDetails")) : {};
  } catch (error) {
    console.log('getError', error)
    state = initialState
    localStorage.setItem('personaDetails', JSON.stringify(state))
  }
  
  switch (action.type) {
    case 'SAVE_PERSONA':
      let newKeyValue = {}
      newKeyValue[action.value.personaDetailsKey] = action.value.personaDetailsValue

      // console.log('saving to redux state = ....', state)
      localStorage.setItem('personaDetails', JSON.stringify({ ...state, ...newKeyValue }))
      return { ...state, ...newKeyValue }
    case 'VIEW_PERSONA':
      return { ...state }
    default:
      return state
  }
}
export default personaReducer